export default values => {
  const errors = {};

  if (!values.password || !values.password.trim()) {
    errors.password = 'Required';
  }
  if (!values.username || !values.username.trim()) {
    errors.username = 'Required';
  }

  return errors;
};
